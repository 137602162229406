import axios, { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import { Bounce, toast } from "react-toastify";

interface ErrorResponse {
    error: string;
}

interface UserData{
    email: string,
    password: string
}

export function handleErrorFactory(navigate: ReturnType<typeof useNavigate>) {
    return function handleError(error: AxiosError) {
      if (error.code === "ERR_NETWORK") {
        navigate("/offline");
        return;
      }
  
      if (error.response) {
        if (error.response.status === 500) {
          navigate("/500");
          return;
        }
  
        const errorResponse = error.response.data as ErrorResponse;
        toast.error(errorResponse.error, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          transition: Bounce,
        });
      } else {
        toast.error(error.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          transition: Bounce,
        });
      }
    };
  }
  
  
  export const sendDataToApi = (
    apiUrl: string,
    redirectLink: string,
    values: UserData,
    navigate: ReturnType<typeof useNavigate>
  ) => {
    const handleError = handleErrorFactory(navigate);
  
    axios
      .post(apiUrl, values, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((response) => {
        toast.info(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          transition: Bounce,
        });
      })
      .catch((error: AxiosError) => {
        handleError(error);
      });
  };