import React from 'react';
import './hero.css';
import { Link } from 'react-router-dom';

const Hero = () => {
  return (
    <section className="section pb-0 hero-section" id="hero">
        {/* <div className="bg-overlay bg-overlay-pattern" style={{ opacity: 0.2, backgroundColor: 'transparent' }}></div> */}
        <div className='container'>
          <div className="row justify-content-start">
            <div className="col-lg-12 col-sm-14">
              <div className="row">
                <div className="col-lg-7 text-center mt-lg-5 pt-5 mb-lg-3">
                  <h1 className="display-4 fw-semibold mb-3 lh-2">Your gateway to <span style={{ color: '#d9c4ab' }}> funding</span> and efficiency</h1>
                  <p className="lead text-muted lh-1.5 fs-5">Oasys is a web application that helps event organizers 
                    secure event funding through proprietary match-making algorithms</p>
                  <div className="d-flex gap-2 justify-content-center mt-4">
                    <Link to="/" className="btn btn-primary">Get Started <i className="ri-arrow-right-line align-middle ms-1"></i></Link>
                    <Link to="/" className="btn btn-danger">Watch Demo <i className="ri-eye-line align-middle ms-1"></i></Link>
                  </div>
                </div>
                <div className="col-lg-5 mt-4 mt-sm-5 pt-sm-5 mb-sm-n5 demo-carousel">
                  <div className="demo-img-patten-top d-none d-sm-block">
                    <img src="assets/images/landing/img-pattern.png" className="d-block img-fluid" alt="..." />
                  </div>
                  <div className="demo-img-patten-bottom d-none d-sm-block">
                    <img src="assets/images/landing/img-pattern.png" className="d-block img-fluid" alt="..." />
                  </div>
                  <div className="carousel slide carousel-fade" data-bs-ride="carousel">
                    <div className="carousel-inner shadow-lg p-2 bg-white rounded">
                      <div className="carousel-item active" data-bs-interval="2000">
                        <img src="assets/images/demos/oasy1.jpg" className="d-block w-100" alt="..." />
                      </div>
                      <div className="carousel-item" data-bs-interval="2000">
                        <img src="assets/images/demos/oasy2.jpg" className="d-block w-100" alt="..." />
                      </div>
                      <div className="carousel-item" data-bs-interval="2000">
                        <img src="assets/images/demos/oasy3.jpg" className="d-block w-100" alt="..." />
                      </div>
                      <div className="carousel-item" data-bs-interval="2000">
                        <img src="assets/images/demos/oasy4.jpg" className="d-block w-100" alt="..." />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="position-absolute start-0 end-0 bottom-0 hero-shape-svg">
          <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 1440 120">
            <g mask="url(&quot;#SvgjsMask1003&quot;)" fill="none">
              <path d="M 0,118 C 288,98.6 1152,40.4 1440,21L1440 140L0 140z"></path>
            </g>
          </svg>
        </div>
      </section>
  );
};

export default Hero;
