import React from 'react';
import * as Yup from 'yup';
import { LuUser } from "react-icons/lu";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from 'react-toastify';
import { PiCopyrightLight } from 'react-icons/pi';
import { Link, useNavigate } from "react-router-dom";
import { IoLockClosedOutline } from "react-icons/io5";
import { Form, Formik, Field, ErrorMessage } from "formik";

import './Form.css';
import { sendDataToApi } from './FormUtils';
import FormTitle from "../../molecules/form-title/FormTitle";
import OauthLoginBtn from '../../molecules/oauth-login/OauthLoginBtn';

const RegisterForm = ()=>{
    const navigate = useNavigate();
    const validate = Yup.object({
        email: Yup.string().email()
        .required('Fill this field'),
        password: Yup.string()
        .min(8, 'Password should contain at least 8 characters')
        .required('Fill this field'),
        confirm_password: Yup.string()
        .oneOf([Yup.ref('password')], 'Passwords must match')
        .required('Fill this field')
    })
    return(
        <Formik
        initialValues={{
            email: '',
            password: '',
            confirm_password: ''
        }}
        validationSchema={validate}
        onSubmit={(values)=>{
            let beneficiaryApiUrl = process.env.REACT_APP_BENEFICIARY_REGISTRATION_URL as string;
            sendDataToApi(beneficiaryApiUrl, '', values, navigate);
        }}
        >
            {
                formik =>(
                    <Form className="formContainer" onSubmit={formik.handleSubmit}>
                        <ToastContainer />
                        <FormTitle title="Sign Up" text="Create your account today" />
                        <OauthLoginBtn microsoft='Sign Up With Microsoft' google='Sign Up With Google' />
                        <div className="formField" id="sponsorRegistration">
                            <div className="hrContainer">
                                <hr className="leftHr" />
                                <small className="hrText">or use email</small>
                                <hr className="rightHr" />
                            </div>
                            <div className="fieldAndIcon">
                                <div className="icon">
                                    <LuUser />
                                </div>
                                <Field autoComplete="off" className="formInput" id="email" name="email" type="email" placeholder="Email" onChange={formik.handleChange} value={formik.values.email} />
                            </div>
                            <ErrorMessage name="email" component="div" className="loginError" />
                            <div className="fieldAndIcon">
                                <div className="icon">
                                    <IoLockClosedOutline />
                                </div>
                                <Field autoComplete="off" className="formInput" id="password" name="password" type="password" placeholder="Password" onChange={formik.handleChange} value={formik.values.password} />
                            </div>
                            <ErrorMessage name="password" component="div" className="loginError" />
                            <div className="fieldAndIcon">
                                <div className="icon">
                                    <IoLockClosedOutline />
                                </div>
                                <Field autoComplete="off" className="formInput" id="confirm_password" name="confirm_password" type="password" placeholder="Confirm Password" onChange={formik.handleChange} value={formik.values.confirm_password} />
                            </div>
                            <ErrorMessage name="confirm_password" component="div" className="loginError" />
                            <div className="termsAgreement">
                                <input type="checkbox" name="privacyPolicy" id="privacyPolicy" />
                                <label htmlFor="privacyPolicy">I accept the <span className="privacyPolicyBold">terms</span> and <span className="privacyPolicyBold">privacy policy</span></label>
                            </div>
                            <input className="submitBtn" type="submit" value = "Register" />
                            <small className="formText">Already have an account? <Link to="/login"><span>Login</span></Link></small>
                        </div>
                        <div className="copyrightContainer">
                            <small className="copyright">
                                <span>
                                    <img src={require('../../../assets/Logo.png')} alt='logo'/>
                                </span>
                                <span>
                                    <PiCopyrightLight /> 
                                </span>
                                <span>
                                    {new Date().getFullYear()} 
                                </span>
                                <span>
                                    Oasys
                                </span>
                                <span>
                                    All rights reserved.
                                </span>
                            </small>
                        </div>
                    </Form>
                )
            }
        </Formik>
    )
}

export default RegisterForm;