import React from "react";
import '../FormPage.css';
import LoginForm from "../../../../UI/organisms/form/LoginForm";
import FormSide from "../../../../UI/organisms/form-side/FormSide";

const Login = ()=>{
    return(
        <div className="page">
            <div className="formPage">
                <FormSide />
                <LoginForm />
            </div>
        </div>
    )
}

export default Login;