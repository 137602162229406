import React from "react";
import { PiCopyrightLight } from "react-icons/pi";

const Copyright = ()=>{
    return(
        <div className="copyrightContainer">
            <small className="copyright">
                <span>
                    <img src={require('../../../assets/Logo.png')} alt="logo"/>
                </span>
                <span>
                    <PiCopyrightLight /> 
                </span>
                <span>
                    {new Date().getFullYear()} 
                </span>
                <span>
                    Oasys
                </span>
                <span>
                    All rights reserved.
                </span>
            </small>
        </div>
    )
}

export default Copyright;