import React from "react";

import '../FormPage.css';
import FormSide from "../../../../UI/organisms/form-side/FormSide";
import SponserRegisterForm from "../../../../UI/organisms/form/SponserRegisterForm";

const SponserRegistration = ()=>{
    return(
        <div className="page">
            <div className="formPage">
                <FormSide />
                <SponserRegisterForm />
            </div>
        </div>
    )
}

export default SponserRegistration;