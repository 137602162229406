import React from "react";

import './FormSide.css';

const FormSide = ()=>{
    
    return(
        <div className="formSide">
            
        </div>
    )
}

export default FormSide;