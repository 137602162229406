import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/autoplay';
import { Autoplay } from 'swiper/modules';
import './client.css';

const Client = () => {
  return (
    <div className="pt-5 mt-5">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="text-center mt-5">
              <h5 className="fs-20">
                Trusted <span className="text-primary text-decoration-underline">by</span> the world's best
              </h5>
              <div className="swiper trusted-client-slider mt-sm-5 mt-4 mb-sm-5 mb-4" dir="ltr">
                <Swiper
                  modules={[Autoplay]}
                  spaceBetween={50}
                  slidesPerView={4}
                  autoplay={{
                    delay: 1000,
                    disableOnInteraction: false,
                  }}
                  loop={true}
                >
                  {/* <SwiperSlide>
                    <div className="client-images">
                      <img src={require("../../../assets/amazon.svg").default} alt="client-img" className="mx-auto img-fluid d-block" />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="client-images">
                      <img src={require('../../../assets/Microsoft-logo.png').default} alt="client-img" className="mx-auto img-fluid d-block" />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="client-images">
                      <img src={require('../../../assets/lenovo.svg').default} alt="client-img" className="mx-auto img-fluid d-block" />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="client-images">
                      <img src={require('../../../assets/paypal.svg').default} alt="client-img" className="mx-auto img-fluid d-block" />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="client-images">
                      <img src={require('../../../assets/TedX.webp').default} alt="client-img" className="mx-auto img-fluid d-block" />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="client-images">
                      <img src={require('../../../assets/verizon.svg').default} alt="client-img" className="mx-auto img-fluid d-block" />
                    </div>
                  </SwiperSlide> */}
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Client;
