import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

import './App.css';
import Login from './components/pages/authentication/login/Login';
import ToggleUser from './components/pages/authentication/register/ToggleUser';
import BenefiaciaryRegistration from './components/pages/authentication/register/BeneficiaryRegistration';
import SponserRegistration from './components/pages/authentication/register/SponsorRegistration';
import Landing from './components/pages/home/landing/Landing';
import Password from './components/pages/authentication/password/Password';
import NewPassword from './components/pages/authentication/password/NewPassword';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Error404Page from './components/pages/error_pages/error_404/Error_404';
import Error500Page from './components/pages/error_pages/error_500/Error_500';
import OfflineErrorPage from './components/pages/error_pages/offline_error/OfflineErrorPage';
import Logout from './components/pages/logout/logout';
import ConfirmEmail from './components/pages/email/email';

function App() {
  const clientId = process.env.REACT_APP_CLIENTID as string;
  return (
    <GoogleOAuthProvider
      clientId={clientId}
    >
      <Router>
        <Routes>
          <Route path='/' element={<Landing />}/>
          <Route path='/login' element={<Login />} />
          <Route path='/register' element={<ToggleUser />} />
          <Route path='/forgot-pwd' element={<Password />} />
          <Route path='/new-pwd' element={<NewPassword />} />
          <Route path='/500' element={<Error500Page />} />
          <Route path='/404' element={<Error404Page />} />
          <Route path='/offline' element={<OfflineErrorPage />} />
          <Route path='/sponser/register' element={<SponserRegistration/>}  />
          <Route path='/beneficiary/register' element={<BenefiaciaryRegistration/>}  />
          <Route path='*' element={<Navigate to='/404' replace />} />
          <Route path='/logout' element={<Logout />} />
          <Route path='/email' element={<ConfirmEmail />} />
        </Routes>
      </Router>
    </GoogleOAuthProvider>
  );
}

export default App;
