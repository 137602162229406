import React from "react";
import { Link } from "react-router-dom";
import { IoMdHome } from "react-icons/io";

import './Error_500.css';

const Error500Page = ()=>{
    return(
        <div className="auth-page-wrapper py-5 d-flex justify-content-center align-items-center min-vh-100">
            <div className="auth-page-content overflow-hidden p-0">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-xl-4 text-center">
                            <div className="error-500 position-relative">
                                <img src={require('../../../../assets/error500.png')} alt="500 Error" className="img-fluid error-500-img swinging-img" />
                                <h1 className="title text-muted">500</h1>
                            </div>
                            <div className="errorMessage">
                                <h4>Internal Server Error!</h4>
                                <p className="text-muted w-75 mx-auto">Server Error 500. We're not exactly sure what happened, but our servers say something is wrong.</p>
                                <Link to="/" className="btn500"><IoMdHome />Back to home</Link>
                         </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Error500Page