import React from "react";
import {FaGoogle, FaMicrosoft} from "react-icons/fa"


import './OauthLoginBtn.css'
// import { useGoogleLogin } from "@react-oauth/google";
interface props{
    microsoft: string,
    google: string
}
const OauthLoginBtn = ({microsoft, google}: props)=>{

    // const googleOauthLogin = useGoogleLogin({
    //     onSuccess: codeResponse => console.log(codeResponse),
    //     flow: 'auth-code'
    // })

    return(
        <div className='oauthLoginContainer' >
            <button> <span><FaMicrosoft /></span> {microsoft}</button>
            <button > <span><FaGoogle /></span> {google}</button>
        </div>
    )
}

export default OauthLoginBtn;