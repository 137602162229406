import React from "react";

import '../FormPage.css'
import NewPwd from "../../../../UI/organisms/form/NewPwd";
import FormSide from "../../../../UI/organisms/form-side/FormSide";


const NewPassword = ()=>{
    return(
        <div className="page">
            <div className="formPage">
                <FormSide />
                <NewPwd />
            </div>
        </div>
    )
}

export default NewPassword;