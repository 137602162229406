import React from "react";

import '../FormPage.css';
import FormSide from "../../../../UI/organisms/form-side/FormSide";
import ToggleUserForm from "../../../../UI/organisms/form/ToggleUserForm";

const ToggleUser = ()=>{
    return(
        <div className="page">
            <div className="formPage">
            <FormSide /> 
            <ToggleUserForm />
            </div>
        </div>
    )
}

export default ToggleUser;