import React from "react";
import { SlRefresh } from "react-icons/sl";
import './OfflineErrorPage.css';

const OfflineErrorPage = ()=>{
    return(
        <div className="auth-page-wrapper d-flex min-vh-100">
            <div className="auth-left-side d-flex justify-content-center align-items-center">
                <img src={require('../../../../assets/Oaasyss.jpeg')} alt="Company Logo" className="company-logo" />
            </div>
            <div className="col-lg-6 p-lg-5 p-4 auth-right-side">
                <div>
                    <div className="text-center">
                        <img src="https://img.themesbrand.com/velzon/images/auth-offline.gif" alt="Offline" height="210" />
                        <h5 className="mt-2 fw-semibold">You're currently offline</h5>
                        <p className="text-muted mb-2 fs-8">
                            You aren't connected to the internet. Hit the button below
                        </p>
                        <button className="offlineBtn" onClick={() => window.location.reload()}>
                            <SlRefresh /> Refresh
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default OfflineErrorPage