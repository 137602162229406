import React from "react";
import '../FormPage.css';
import FormSide from "../../../../UI/organisms/form-side/FormSide";
import ForgotPwd from "../../../../UI/organisms/form/ForgortPwd";

const Password = ()=>{
    return(
        <div className="page">
            <div className="formPage">
                <FormSide />
                <ForgotPwd />
            </div>
        </div>
    )
}

export default Password;