import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'

import './Landing.css';
import Navbar from '../../../../UI/organisms/navbar/navbar';
import Hero from '../../../../UI/organisms/hero/hero';
import Client from '../../../../UI/organisms/client/client';
import Features from '../../../../UI/organisms/feature/feature';
import Services from '../../../../UI/organisms/service/service';
import FAQs from '../../../../UI/organisms/FAQ/faq';
import Review from '../../../../UI/organisms/review/review';
import Counter from '../../../../UI/organisms/counter/counter';
import Team from '../../../../UI/organisms/team/team';
import Contact from '../../../../UI/organisms/contact/contact';
import Footer from '../../../../UI/organisms/footer/footer';



function Landing() {
  return (
    <div className="layout-wrapper landing">
      <Navbar />
      <Hero />
      <Client />
      <Services />
      <Features />
      <FAQs />
      {/* <Review /> */}
      <Counter />
      <Team />
      <Contact />
      <Footer />
    </div>
  );
}

export default Landing;
