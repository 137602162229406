import React from "react";

import './Error_404.css';
import { Link } from "react-router-dom";

const Error404Page = ()=>{
    return(
        <div className="auth-page-wrapper d-flex justify-content-center align-items-center min-vh-100">
            <div className="auth-page-content text-center">
                <img src={require('../../../../assets/error400-cover.png')} alt="error img" className="error-image" />
                <div>
                    <h4 className="text-uppercase">Sorry, Page not Found 😭</h4>
                    <p className="text-muted">The page you are looking for is not available!</p>
                    <Link to="/" className="homeBtn"><i className="mdi mdi-home me-1"></i>Back home</Link>
                </div>
            </div>
        </div>
    )
}

export default Error404Page;