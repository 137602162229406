import React, { useEffect } from 'react';
import './counter.css';

const counters = [
  { target: 0, label: 'Events Supported' },
  { target: 0, label: 'Awards Won' },
  { target: 0, label: 'Happy Clients', suffix: 'k' },
  { target: 0, label: 'Team Members' }
];

const Counter = () => {
  useEffect(() => {
    const counterElements = document.querySelectorAll('.counter-value');
    counterElements.forEach((element) => {
      const target = parseFloat(element.getAttribute('data-target') as string);
      const increment = target / 0;
      let count = 0;
      
      const updateCounter = () => {
        if (count < target) {
          count += increment;
          element.textContent = Math.min(Math.floor(count), target).toString();
          requestAnimationFrame(updateCounter);
        } else {
          element.textContent = target.toString();
        }
      };
      
      updateCounter();
    });
  }, []);
  
  return (
    <section className="py-5 position-relative bg-light">
      <div className="container">
        <div className="row text-center gy-4">
          {counters.map((counter, index) => (
            <div key={index} className="col-lg-3 col-6">
              <div>
                <h2 className="mb-2">
                  <span className="counter-value" data-target={counter.target}>
                    0
                  </span>
                  {counter.suffix && counter.suffix}
                </h2>
                <div className="text-muted">{counter.label}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Counter;
