import React from "react";
import { Link } from "react-router-dom";

import './Form.css';
import FormTitle from "../../molecules/form-title/FormTitle";
import Copyright from "../../molecules/copyright-footer/Copyright";

const ToggleUserForm = ()=>{
    return(
        <div className="formContainer">
            <FormTitle 
            title="Select your Role" 
            text="Please choose the role that best describes you. Are you looking to invest in opportunities as a Sponsor, or seeking support and growth as a Beneficiary?" />
            <div className="userRoleBtnContainer">
                <Link to="/sponser/register">Sponsor</Link>
                 <Link to="/beneficiary/register">Beneficiary </Link>
            </div>
            <Copyright />
        </div>
    )
}

export default ToggleUserForm;