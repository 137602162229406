import { useEffect } from "react";
import lottie from "lottie-web";
import { defineElement } from "lord-icon-element";

const LordIconComponent = () => {
  useEffect(() => {
    defineElement(lottie.loadAnimation);
  }, []);

  return (
    <lord-icon
      src="https://cdn.lordicon.com/rhvddzym.json"
      trigger="loop"
      colors="primary:#183e54"
      className="avatar-xl lordIcon"
      style={{ width: "100px", height: "100px" }}
    ></lord-icon>
  );
};

export default LordIconComponent;
