import React from "react";
import * as Yup from 'yup';
import { Link } from "react-router-dom";
import { LuUser } from "react-icons/lu";
import { IoLockClosedOutline } from "react-icons/io5";
import { ErrorMessage, Field, Formik, Form } from "formik";

import './Form.css';
import FormTitle from "../../molecules/form-title/FormTitle";
import Copyright from "../../molecules/copyright-footer/Copyright";

const SponserRegisterForm = ()=>{
    const validate = Yup.object({
        email: Yup.string().email()
        .required('Fill this field'),
        password: Yup.string()
        .min(8, 'Password should contain at least 8 characters')
        .required('Fill this field'),
        confirm_password: Yup.string()
        .oneOf([Yup.ref('password')], 'Passwords must match')
        .required('Fill this field')
    })
    return(
        <Formik
        initialValues={{
            email: '',
            password: '',
            confirm_password: ''
        }}
        validationSchema={validate}
        onSubmit={(values)=>{
            console.log(values)
        }}
        >
            {
                formik =>(
                    <Form className="formContainer" onSubmit={formik.handleSubmit}>
                        <FormTitle title="Sign Up" text="Create your account today" />
                        <div className="formField" id="sponsorRegistration">
                            <div className="fieldAndIcon">
                                <div className="icon">
                                    <LuUser />
                                </div>
                                <Field autoComplete="off" className="formInput" id="email" name="email" type="email" placeholder="Company Email" onChange={formik.handleChange} value={formik.values.email} />
                            </div>
                            <ErrorMessage name="email" component="div" className="loginError" />
                            <div className="fieldAndIcon">
                                <div className="icon">
                                    <IoLockClosedOutline />
                                </div>
                                <Field autoComplete="off" className="formInput" id="password" name="password" type="password" placeholder="Password" onChange={formik.handleChange} value={formik.values.password} />
                            </div>
                            <ErrorMessage name="password" component="div" className="loginError" />
                            <div className="fieldAndIcon">
                                <div className="icon">
                                    <IoLockClosedOutline />
                                </div>
                                <Field autoComplete="off" className="formInput" id="confirm_password" name="confirm_password" type="password" placeholder="Confirm Password" onChange={formik.handleChange} value={formik.values.confirm_password} />
                            </div>
                            <ErrorMessage name="confirm_password" component="div" className="loginError" />
                            <div className="termsAgreement">
                                <input type="checkbox" name="privacyPolicy" id="privacyPolicy" />
                                <label htmlFor="privacyPolicy">I accept the <span className="privacyPolicyBold">terms</span> and <span className="privacyPolicyBold">privacy policy</span></label>
                            </div>
                            <input className="submitBtn" type="submit" value = "Register" />
                            <small className="formText">Already have an account? <Link to="/login"><span>Login</span></Link></small>
                        </div>
                        <Copyright />
                    </Form>
                )
            }
        </Formik>
    )
}

export default SponserRegisterForm;