import React from 'react';
import { Accordion, Button } from 'react-bootstrap';
import './faq.css';

const FAQs = () => {
  return (
    <section className="section" id="FAQ">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="text-center mb-5">
              <h3 className="mb-3 fw-semibold">Frequently Asked Questions</h3>
              <p className="text-muted mb-4 ff-secondary">
                If you cannot find an answer to your question in our FAQ, you can always contact us or email us. We will answer you shortly!
              </p>

              <div className="">
                <Button variant="primary" className="btn-label rounded-pill">
                  <i className=" label-icon align-middle rounded-pill fs-16 me-2" style={{ display: 'inline-block', width: '1em', height: '1em' }}></i> Email Us
                </Button>
                <Button variant="info" className="btn-label rounded-pill ms-2">
                  <i className=" label-icon align-middle rounded-pill fs-16 me-2"></i> Contact Us
                </Button>
              </div>
            </div>
          </div>
        </div>

        <div className="row g-lg-5 g-4">
          <div className="col-lg-6">
            <Accordion id="genques-accordion">
              <Accordion.Item eventKey="0">
                <Accordion.Header>What is Oasys?</Accordion.Header>
                <Accordion.Body className="ff-secondary">
                  Oasys is an innovative web-based platform designed to streamline the sponsorship experience for event organizers, startup founders, and organizations seeking sponsorship opportunities. It facilitates seamless communication, secure financial transactions, and transparent sponsorship management, benefiting both sponsors and beneficiaries.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>How does Oasys work?</Accordion.Header>
                <Accordion.Body className="ff-secondary">
                  Oasys operates through two portals: the Beneficiary Portal and the Sponsor Portal. Beneficiaries can easily create event profiles, apply for sponsorships, track application progress, and access support through their portal. Sponsors, on the other hand, can create sponsorship listings, review applications, manage finances, and communicate with beneficiaries via their dedicated portal.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>Who can benefit from using Oasys?</Accordion.Header>
                <Accordion.Body className="ff-secondary">
                  Oasys caters to a diverse range of users, including event organizers planning hackathons, conferences, charity events, and more. Startup founders seeking funding for their ventures, marketing and PR teams responsible for event promotion, government agencies organizing public events, educational institutions hosting workshops, and non-profit organizations planning fundraising events can all benefit from Oasys.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>What features does Oasys offer?</Accordion.Header>
                <Accordion.Body className="ff-secondary">
                  Oasys offers a range of features including event discovery, sponsorship listings, secure transactions, performance analytics, and machine learning-powered matchmaking. It also provides tools for efficient communication, fraud prevention, eligibility validation, and personalized support, ensuring a comprehensive sponsorship management experience.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>

          <div className="col-lg-6">
            <Accordion id="privacy-accordion">
              <Accordion.Item eventKey="0">
                <Accordion.Header>How does Oasys ensure security and transparency?</Accordion.Header>
                <Accordion.Body className="ff-secondary">
                  Oasys employs robust security measures to safeguard financial transactions, validate beneficiary identities, and prevent fraud. It also provides transparent communication channels, detailed analytics, and audit trails to ensure trust and accountability throughout the sponsorship process.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Can Oasys accommodate different types and sizes of events?</Accordion.Header>
                <Accordion.Body className="ff-secondary">
                  Yes, Oasys is designed to accommodate events of any size and nature, including hackathons, conferences, charity events, concerts, marathons, workshops, and more. Its flexible and scalable platform can cater to the needs of diverse event organizers and sponsors, ensuring inclusivity and accessibility.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>Can Oasys accommodate events and startups from underserved areas?</Accordion.Header>
                <Accordion.Body className="ff-secondary">
                  Yes, Oasys aims to foster equity in the event sponsorship and startup funding landscape by increasing visibility for events and startups in underserved areas. The platform provides equal opportunities for organizations and startups to secure sponsorship/funding from any organization around the globe, thereby promoting inclusivity and diversity.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>What sets Oasys apart from other sponsorship platforms?</Accordion.Header>
                <Accordion.Body className="ff-secondary">
                  Oasys distinguishes itself through its user-friendly interface, comprehensive features, use of artificial intelligence for matchmaking, and focus on the B2B event market. Oasys prioritizes security, transparency, and inclusivity, offering a holistic solution for both sponsors and beneficiaries in the sponsorship ecosystem.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQs;
