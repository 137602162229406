import React, { useState } from 'react';

const ConfirmEmail = () => {
    const [digits, setDigits] = useState({ digit1: '', digit2: '', digit3: '', digit4: '' });
    const handleChange = (e, nextInputId) => {
        const { id, value } = e.target;
        if (value.length === 1 && nextInputId) {
          document.getElementById(nextInputId).focus();
        }
        setDigits({ ...digits, [id]: value });
      };
 return (
    <div className="row vh-100 g-0">
      <div className="col-lg-6 position-relative d-none d-lg-block">
      <img src="./Oaasyss.jpeg" alt="Oasys Logo" width="750" height="703" />
      </div>
      <div className="col-lg-6 d-flex justify-content-center align-items-center flex-column">
      <div className="p-lg-5 p-4">
        <div className="mb-4">
          <div className="avatar-lg mx-auto">
            <div className="avatar-title bg-light text-primary display-5 rounded-circle">
              <i className="ri-mail-line"></i>
            </div>
          </div>
        </div>
        <div className="text-center mx-lg-3">
          <h4 className="mb-3">Verify Your Email</h4>
          <p>
            Please enter the 4 digit code sent to <span className="fw-semibold">example@abc.com</span>
          </p>
        </div>

        <div className="mt-6">
          <form autoComplete="off">
            <div className="row justify-content-center">
              {['digit1', 'digit2', 'digit3', 'digit4'].map((digit, index) => (
                <div className="col-2" key={digit}>
                  <div className="mb-3">
                    <label htmlFor={digit} className="visually-hidden">
                      Digit {index + 1}
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-lg bg-light border-light text-center"
                      maxLength="1"
                      id={digit}
                      value={digits[digit]}
                      onChange={(e) =>
                        handleChange(e, index < 3 ? `digit${index + 2}` : null)
                      }
                    />
                  </div>
                </div>
              ))}
            </div>

            <div className="mt-3 d-flex justify-content-center">
              <button type="button" className="btn btn-success w-50">
                Confirm
              </button>
            </div>
          </form>
        </div>

        <div className="mt-5 text-center">
          <p className="mb-4">
            Didn't receive a code?{' '}
            {/* <a
              href="#"
              className="fw-semibold text-primary text-decoration-underline"
            >
              Resend
            </a> */}
          </p>
          <p className="mb-1 text-center">
                  &copy; {new Date().getFullYear()} Oasys. All rights reserved.
          </p>
        </div>
      </div>
    </div>
    </div>
  );
};

export default ConfirmEmail;
