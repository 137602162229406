import React from "react";

import '../FormPage.css';
import FormSide from "../../../../UI/organisms/form-side/FormSide";
import RegisterForm from "../../../../UI/organisms/form/RegisterForm";

const BenefiaciaryRegistration = ()=>{
    return(
        <div className="page">
            <div className="formPage">
                <FormSide />
                <RegisterForm />
            </div>
        </div>
    )
}
export default BenefiaciaryRegistration 