import React from 'react';
import './service.css';
import { Link } from 'react-router-dom';

const Services = () => {
  return (
    <section className="section .content-section" id="services">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="text-center mb-5">
              <h1 className="mb-3 ff-secondary fw-semibold lh-base">Take the hassle out of event funding</h1>
              <p className="text-muted">Tired of the usual tedious and ineffective process of securing sponsorship for your event? 
                Look no further than Oasys, the innovative platform that manages the entire sponsorship process, 
                making it easier than ever for sponsors to find the right events to fund and for beneficiaries to get funded”</p>
            </div>
          </div>
        </div>
        <div className="row g-3">
          <div className="col-lg-4">
            <div className="d-flex p-3">
              <div className="flex-shrink-0 me-3">
                <div className="avatar-sm icon-effect">
                  <div className="avatar-title bg-transparent text-success rounded-circle" style={{ color: '#d9c4ab' }}>
                    <i className="ri-lightbulb-flash-line fs-36"></i>
                  </div>
                </div>
              </div>
              <div className="flex-grow-1">
                <h5 className="fs-18">Connecting Sponsors and Beneficiaries</h5>
                <p className="text-muted my-3 ff-secondary">Organizers can effortlessly create event profiles, 
                  reach a vast pool of potential sponsors, manage applications 
                  and track their goals. Sponsors can explore a diverse range of events tailored to their interests, 
                  find ideal partnerships that resonate with their brand and effectively manage their sponsorship activities.</p>
                <div>
                  <Link to="/" className="fs-13 fw-medium" style={{ color: '#183e54' }}>Learn More <i className="ri-arrow-right-s-line align-bottom"></i></Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="d-flex p-3">
              <div className="flex-shrink-0 me-3">
                <div className="avatar-sm icon-effect">
                  <div className="avatar-title bg-transparent text-success rounded-circle" style={{ color: '#d9c4ab' }}>
                    <i className="ri-stack-line fs-36"></i>
                  </div>
                </div>
              </div>
              <div className="flex-grow-1">
                <h5 className="fs-18">Streamlining the Sponsorship Process</h5>
                <p className="text-muted my-3 ff-secondary">Beneficiaries can breeze through applications with auto-save 
                  and data integration, while AI finds them the perfect campaign among potential sponsors. 
                  Fast and secure payouts ensure trust and peace of mind, allowing you to focus on making your event a success.</p>
                <div>
                  <Link to="#" className="fs-13 fw-medium" style={{ color: '#183e54' }}>Learn More <i className="ri-arrow-right-s-line align-bottom"></i></Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="d-flex p-3">
              <div className="flex-shrink-0 me-3">
                <div className="avatar-sm icon-effect">
                  <div className="avatar-title bg-transparent text-success rounded-circle" style={{ color: '#d9c4ab' }}>
                    <i className="ri-slideshow-line fs-36"></i>
                  </div>
                </div>
              </div>
              <div className="flex-grow-1">
                <h5 className="fs-18">Data-driven Insights and Impact</h5>
                <p className="text-muted my-3 ff-secondary">Sponsors go beyond just providing sponsorship. 
                  Track the impact and success of your partnerships with detailed analytics, measure the positive 
                  change your events create, build stronger relationships and gain valuable insights to improve future campaigns.</p>
                <div>
                  <Link to="/" className="fs-13 fw-medium" style={{ color: '#183e54' }}>Learn More <i className="ri-arrow-right-s-line align-bottom"></i></Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
