import React from 'react';
import './team.css';
import { Link } from 'react-router-dom';

const teamMembers = [
  {
    name: 'Moses Qurlarmah',
    role: ['Founder', 'Project Manager'],
    image: 'assets/images/team/Moses.jpg',
    linkedin: 'https://www.linkedin.com/in/kalama-moses-a405971b0/'
  },
  {
    name: 'Grace Were',
    role: ['Co-founder', 'Frontend Developer'],
    image: 'assets/images/team/Grace.jpg',
    linkedin: 'https://www.linkedin.com/in/grace-were/'
  },
  {
    name: 'Paul Sanga',
    role: ['Co-founder', 'Backend Developer'],
    image: 'assets/images/team/Paul.jpeg',
    linkedin: 'https://www.linkedin.com/in/kalama-moses-a405971b0/'
  },
  {
    name: 'Topister Nandera',
    role: ['Co-founder', 'Database Engineer'],
    image: 'assets/images/team/Topister.jpg',
    linkedin: 'https://www.linkedin.com/in/topister-nandera-5930331a5/'
  },
  // {
  //   name: 'Pius Mutuma',
  //   role: ['Co-founder', 'Database Engineer'],
  //   image: 'assets/images/team/Pius.jpeg',
  //   linkedin: 'https://www.linkedin.com/in/pius-mutuma-kimathi/'
  // },
];

const Team = () => {
  return (
    <section className="section bg-light" id="team">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="text-center mb-5">
              <h3 className="mb-3 fw-semibold">
                Meet the <span className="text-danger">Team</span>
              </h3>
            </div>
          </div>
        </div>
        <div className="row">
          {teamMembers.map((member, index) => (
            <div key={index} className="col-lg-3 col-sm-6">
              <div className="card">
                <div className="card-body text-center p-4">
                  <div className="avatar-xl mx-auto mb-4 position-relative">
                    <img src={member.image} alt={member.name} className="img-fluid rounded-circle" />
                    {member.linkedin && (
                      <Link
                        to={member.linkedin}
                        className="btn btn-success btn-sm position-absolute bottom-0 end-0 rounded-circle avatar-xs"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div className="avatar-title bg-transparent">
                          <i className="ri-linkedin-fill align-bottom"></i>
                        </div>
                      </Link>
                    )}
                  </div>
                  <h5 className="mb-1">
                    <Link to="#" className="text-body">
                      {member.name}
                    </Link>
                  </h5>
                  {member.role.map((r, i) => (
                    <p key={i} className="text-muted mb-0 ff-secondary">
                      {r}
                    </p>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Team;
