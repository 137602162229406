import React from "react";
import * as Yup from 'yup';
import { ToastContainer } from "react-toastify";
import { IoLockClosedOutline } from "react-icons/io5";
import { ErrorMessage, Field, Form, Formik } from "formik";

import './Form.css';
import FormTitle from "../../molecules/form-title/FormTitle";
import { PiCopyrightLight } from "react-icons/pi";

const NewPwd = ()=>{
    const validate = Yup.object({
        password: Yup.string()
        .min(8, 'Password should contain at least 8 characters')
        .required('Fill this field'),
        confirm_password: Yup.string()
        .oneOf([Yup.ref('password')], 'Passwords must match')
        .required('Fill this field')
    })

    return(
        <Formik
        initialValues={{
            password: '',
            confirm_password: ''
        }}
        validationSchema={validate}
        onSubmit={(values)=>{

        }}
        >
            {
                formik => (
                    <Form className="formContainer" onSubmit={formik.handleSubmit}>
                        <ToastContainer />
                        <FormTitle title="Create new password" text="Your new password must be different from previous used password" />
                        <div className="formField" id="sponsorRegistration">
                            <div className="fieldAndIcon">
                                <div className="icon">
                                    <IoLockClosedOutline />
                                </div>
                                <Field autoComplete="off" className="formInput" id="password" name="password" type="password" placeholder="Password" onChange={formik.handleChange} value={formik.values.password} />
                            </div>
                            <ErrorMessage name="password" component="div" className="loginError" />
                            <div className="fieldAndIcon">
                                <div className="icon">
                                    <IoLockClosedOutline />
                                </div>
                                <Field autoComplete="off" className="formInput" id="confirm_password" name="confirm_password" type="password" placeholder="Confirm Password" onChange={formik.handleChange} value={formik.values.confirm_password} />
                            </div>
                            <ErrorMessage name="confirm_password" component="div" className="loginError" />
                            <Field className="pwdSubmitBtn" type="submit" value = "Submit" />
                        </div>
                        <div className="copyrightContainer">
                            <small className="copyright">
                                <span>
                                    <img src={require('../../../assets/Logo.png')} alt="logo"/>
                                </span>
                                <span>
                                    <PiCopyrightLight /> 
                                </span>
                                <span>
                                    {new Date().getFullYear()} 
                                </span>
                                <span>
                                    Oasys
                                </span>
                                <span>
                                    All rights reserved.
                                </span>
                            </small>
                        </div>
                    </Form>
                )
            }
        </Formik>
    )
}

export default NewPwd;