import React from 'react';
import { GoArrowRight } from "react-icons/go";

import './feature.css';
import { Link } from 'react-router-dom';

const Features = () => {
  return (
    <section className="section" id="features">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="text-center mb-5">
              <h6 style={{ color: '#747d7b' }}>WHY OASYS?</h6>
              <h1 className="mb-3 ff-secondary fw-semibold lh-base">
                Empower Event Sponsorship
              </h1>
              <p className="text-muted">
                Transform the way you manage event sponsorships and foster impactful collaborations effortlessly. Oasys is your all-in-one solution, designed for seamless sponsorship management that maximizes efficiency, reduces costs, and leaves a lasting impact.
              </p>
            </div>
          </div>
          {/* end col */}
        </div>
        {/* end row */}

        <div className="row align-items-center gy-4">
          <div className="col-lg-6 order-2 order-lg-1">
            <div className="text-muted">
              <h5 className="fs-12 text-uppercase text-success" style={{ color: '#d9c4ab' }}>
                ADAPTIVE & COOPERATIVE
              </h5>
              <h4 className="mb-3">Tailor sponsorship management to align with your preferences.</h4>
              <p className="mb-4 ff-secondary">
                Your sponsorship platform should be adaptable to your preferences, empowering you to work seamlessly. Customize your sponsorship processes to match your event's workflow, ensuring smooth transitions and clear communication at every step.
              </p>
              <div className="mt-4">
                <Link to="/" className="btn btn-primary">
                  Discover how Oasys expanded sponsorship reach <GoArrowRight/>
                </Link>
              </div>
            </div>
          </div>
          {/* end col */}
          <div className="col-lg-6 col-sm-7 col-10 ms-auto order-1 order-lg-2">
            <div>
              <img src="assets/images/features/Meet.jpeg" alt="" className="img-fluid" />
            </div>
          </div>
        </div>
        {/* end row */}

        <div className="row align-items-center mt-5 pt-lg-5 gy-4">
          <div className="col-lg-6 col-sm-7 col-10 mx-auto">
            <div>
              <img src="assets/images/features/Meet2.png" alt="" className="img-fluid" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="text-muted ps-lg-5">
              <h5 className="fs-12 text-uppercase text-success" style={{ color: '#d9c4ab' }}>
                VERSATILE & ENGAGING
              </h5>
              <h4 className="mb-3">Design sponsorship campaigns tailored to your objectives</h4>
              <p className="mb-4">
                Your sponsorship platform should offer flexibility, allowing you to tailor campaigns to meet your specific goals. Customize sponsorship packages to suit your target audience, fostering engagement and maximizing impact.
              </p>

              <div className="vstack gap-2">
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0 me-2">
                    <div className="avatar-xs icon-effect">
                      <div className="avatar-title bg-transparent text-success rounded-circle h2">
                        <i className="ri-check-fill"></i>
                      </div>
                    </div>
                  </div>
                  <div className="flex-grow-1">
                    <p className="mb-0">Impactful Sponsorship Design</p>
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0 me-2">
                    <div className="avatar-xs icon-effect">
                      <div className="avatar-title bg-transparent text-success rounded-circle h2">
                        <i className="ri-check-fill"></i>
                      </div>
                    </div>
                  </div>
                  <div className="flex-grow-1">
                    <p className="mb-0">Tailored Engagement Strategies</p>
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0 me-2">
                    <div className="avatar-xs icon-effect">
                      <div className="avatar-title bg-transparent text-success rounded-circle h2">
                        <i className="ri-check-fill"></i>
                      </div>
                    </div>
                  </div>
                  <div className="flex-grow-1">
                    <p className="mb-0">Targeted Packages</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* end col */}
        </div>
        {/* end row */}

        <div className="row align-items-center mt-5 pt-lg-5 gy-4">
          <div className="col-lg-6 col-sm-7 col-10 mx-auto">
            <div className="text-muted">
              <h5 className="fs-12 text-uppercase text-success" style={{ color: '#d9c4ab' }}>
                REACHABLE & EXPANDABLE
              </h5>
              <h4 className="mb-3">Empower your program to grow with demand</h4>
              <p className="mb-4 ff-secondary">
                Your sponsorship platform should be able to accommodate varying needs as they arise. Blend agility and customization with robust security and accessibility to establish a program that's not only efficient but also inclusive and scalable.
              </p>
              <div className="vstack gap-2">
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0 me-2">
                    <div className="avatar-xs icon-effect">
                      <div className="avatar-title bg-transparent text-success rounded-circle h2">
                        <i className="ri-check-fill"></i>
                      </div>
                    </div>
                  </div>
                  <div className="flex-grow-1">
                    <p className="mb-0">Versatile Scalability</p>
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0 me-2">
                    <div className="avatar-xs icon-effect">
                      <div className="avatar-title bg-transparent text-success rounded-circle h2">
                        <i className="ri-check-fill"></i>
                      </div>
                    </div>
                  </div>
                  <div className="flex-grow-1">
                    <p className="mb-0">Inclusive Agility</p>
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0 me-2">
                    <div className="avatar-xs icon-effect">
                      <div className="avatar-title bg-transparent text-success rounded-circle h2">
                        <i className="ri-check-fill"></i>
                      </div>
                    </div>
                  </div>
                  <div className="flex-grow-1">
                    <p className="mb-0">Adaptive Growth</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* end col */}
          <div className="col-lg-6 col-sm-7 col-10 ms-auto order-1 order-lg-2">
            <div>
              <img src="assets/images/features/Meet3.jpg" alt="" className="img-fluid" />
            </div>
          </div>
        </div>
        {/* end row */}
      </div>
      {/* end container */}
    </section>
  );
};

export default Features;
