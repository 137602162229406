import React from "react";

import './FormTitle.css'

interface props{
    title: string,
    text: string
}

const FormTitle = ({title, text}: props)=>{
    return(
        <div className="formTitleContainer">
            <h1 className="title">{title}</h1>
            <p className="formText">{text}</p>
        </div>
    )
}

export default FormTitle;