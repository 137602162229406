import React from 'react';
import './footer.css';
import { Link } from 'react-router-dom';
import Copyright from '../../molecules/copyright-footer/Copyright';

const Footer = () => {
  return (
    <footer className="custom-footer bg-dark py-5 position-relative">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 mt-4">
            <div>
              <div>
                <img src="assets/images/oasys_light.png" alt="logo light" height="50" />
              </div>
              <div className="mt-4 fs-13">
                <p>Empowering mission-driven initiatives.</p>
              </div>
            </div>
          </div>
          <div className="col-lg-7 ms-lg-auto">
            <div className="row">
              <div className="col-sm-4 mt-4">
                <h5 className="text-white mb-0">Company</h5>
                <div className="text-muted mt-3">
                  <ul className="list-unstyled ff-secondary footer-list">
                    <li><Link to="">About Us</Link></li>
                    <li><Link to="">Projects</Link></li>
                    <li><Link to="">Patners</Link></li>
                    <li><Link to="">Security</Link></li>
                  </ul>
                </div>
              </div>
              <div className="col-sm-4 mt-4">
                <h5 className="text-white mb-0">Product</h5>
                <div className="text-muted mt-3">
                  <ul className="list-unstyled ff-secondary footer-list">
                    <li><Link to="">How it works</Link></li>
                    <li><Link to="">Accessibility</Link></li>
                    <li><Link to="">Features</Link></li>
                    <li><Link to="">Resources</Link></li>
                    <li><Link to="">AI Principles</Link></li>
                  </ul>
                </div>
              </div>
              <div className="col-sm-4 mt-4">
                <h5 className="text-white mb-0">Support</h5>
                <div className="text-muted mt-3">
                  <ul className="list-unstyled ff-secondary footer-list">
                    <li><Link to="">FAQ</Link></li>
                    <li><Link to="">Contact</Link></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row text-center text-sm-start align-items-left mt-5">
          <div className="col-sm-6">
            <div>
              <p className="copy-rights mb-0">
                <Copyright />
                {/* <script> document.write(new Date().getFullYear()) </script> © Oasys. All rights reserved. */}
              </p>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="text-sm-end mt-3 mt-sm-0">
              <ul className="list-inline mb-0 footer-social-link">
                <li className="list-inline-item">
                  <Link to="https://www.facebook.com/oasyske" className="avatar-xs d-block">
                    <div className="avatar-title rounded-circle">
                      <i className="ri-facebook-fill"></i>
                    </div>
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link to="https://twitter.com/oasys_ke" className="avatar-xs d-block">
                    <div className="avatar-title rounded-circle">
                      <i className="ri-twitter-fill"></i>
                    </div>
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link to="/" className="avatar-xs d-block">
                    <div className="avatar-title rounded-circle">
                      <i className="ri-linkedin-fill"></i>
                    </div>
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link to="https://www.instagram.com/oasys_ke/" className="avatar-xs d-block">
                    <div className="avatar-title rounded-circle">
                      <i className="ri-instagram-fill"></i>
                    </div>
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link to="/" className="avatar-xs d-block">
                    <div className="avatar-title rounded-circle">
                      <i className="ri-youtube-line"></i>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
