import React from "react";
import * as Yup from 'yup';
import { Link, useNavigate } from "react-router-dom";
import { LuUser } from "react-icons/lu";
import { ToastContainer } from "react-toastify";
import { IoLockClosedOutline } from "react-icons/io5";
import { Form, Formik, Field, ErrorMessage } from "formik";

import './Form.css'
import { sendDataToApi } from "./FormUtils";
import FormTitle from "../../molecules/form-title/FormTitle";
import Copyright from "../../molecules/copyright-footer/Copyright";
import OauthLoginBtn from "../../molecules/oauth-login/OauthLoginBtn";

const LoginForm = ()=>{
    const navigate = useNavigate();
    const validate = Yup.object({
        email: Yup.string().email()
        .required('Email is a required field'),
        password: Yup.string()
        .min(8, 'Password should contain at least 8 characters')
        .required('Password is a required field')
    })
    return(
        <Formik
            initialValues={{
                email: '',
                password: ''
            }}
            validationSchema={validate}
            onSubmit={(values)=>{
                let loginApiUrl = process.env.REACT_APP_LOGIN_URL as string;
                sendDataToApi(loginApiUrl, '', values, navigate);
            }}
        >
            {
                formik =>(
                    <Form className="formContainer" onSubmit={formik.handleSubmit}>
                        <ToastContainer />
                        <FormTitle title="Sign In" text="Get access to your account" />
                        <OauthLoginBtn microsoft="Login with Microsoft" google="Login with Google" />
                        <div className="formField">
                            <div className="hrContainer">
                                <hr className="leftHr" />
                                <small className="hrText">or use email</small>
                                <hr className="rightHr" />
                            </div>
                            <div className="fieldAndIcon">
                                <div className="icon">
                                    <LuUser />
                                </div>
                                <Field autoComplete="off" className="formInput" id="email" name="email" type="email" placeholder="Email" onChange={formik.handleChange} value={formik.values.email} />
                            </div>
                            <ErrorMessage name="email" component="div" className="loginError" />
                            <div className="fieldAndIcon">
                                <div className="icon">
                                    <IoLockClosedOutline />
                                </div>
                                <Field autoComplete="off" className="formInput" id="password" name="password" type="password" placeholder="Password" onChange={formik.handleChange} value={formik.values.password} />
                            </div>
                            <ErrorMessage name="password" component="div" className="loginError" />
                            <div className="rememberMeContainer">
                                <div className="remeberMe">
                                    <input type="radio" name="rememberMe" id="rememberMe" />
                                    <label htmlFor="rememberMe">Remember Me</label>
                                </div>
                                <div className="forgotPwd">
                                    <Link to='/forgot-pwd'>
                                        <small className="forgotPassword">Forgot Password?</small>
                                    </Link>
                                </div>
                            </div>
                            <input className="submitBtn" type="submit" value = "Sign In" />
                            <small className="formText">Don't have an account? <Link to="/register"><span>Create Account</span></Link></small>
                        </div>
                        <Copyright/>
                    </Form>
                )
            }
        </Formik>
    )
}

export default LoginForm;