import React from 'react';
import { Link } from 'react-router-dom';

import './navbar.css';

const Navbar = () => {
  return (
    <nav className="navbar navbar-expand-lg navbar-landing fixed-top" id="navbar">
        <div className="container">
          <Link className="navbar-brand" to="/">
            <img
            src="assets/images/Oasys-dark.png"
            className="card-logo card-logo-dark"
            alt="logo dark"
            height="45"
          />
          <img
            src="assets/images/oasys_light.png"
            className="card-logo card-logo-light"
            alt="logo light"
            height="45"
          />
          </Link>
          <button className="navbar-toggler py-0 fs-20 text-body" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <i className="mdi mdi-menu"></i>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mx-auto mt-2 mt-lg-0" id="navbar-example">
              <li className="nav-item">
                <Link className="nav-link active" to="#hero">Home</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="#services">Services</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="#features">Solutions</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="#FAQ">FAQ</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="#reviews">Reviews</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="#team">Team</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="#contact">Contact</Link>
              </li>
            </ul>

            

            <div>
              <Link to="/login" className="btn btn-link fw-medium text-decoration-none text-dark">Sign in</Link>
              <Link to="/register" className="btn btn-primary">Sign Up</Link>
            </div>
          </div>
        </div>
      </nav>
  );
};

export default Navbar;
