import React from "react";
import * as Yup from 'yup'
import { LuUser } from "react-icons/lu";
import { useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { ErrorMessage, Field, Form, Formik } from "formik";

import './Form.css';
import LordIconComponent from "./EmailIcons";
import Copyright from "../../molecules/copyright-footer/Copyright";

const ForgotPwd = ()=>{
    let location = useLocation();
    const validate = Yup.object({
        email: Yup.string().email()
        .required('Email is a required field')
    })
    return(
        <Formik
            initialValues={{
                email: ''
            }}
            validationSchema={validate}
            onSubmit={(values)=>{
               location.pathname = '/new-pwd'
            }}
        >
            {
                formik => (
                    <Form className="formContainer" onSubmit={formik.handleSubmit}>
                        <ToastContainer />
                        <div className="resetPasswordTitle">
                            <LordIconComponent />
                            <h3>Forgot password?</h3>
                            <p>Reset password with Oasys</p>
                        </div>
                        <div className="instructions">
                            <p>Enter your email and instructions will be sent to you!</p>
                        </div>
                        <div className="formField">
                        <div className="fieldAndIcon">
                            <div className="icon">
                                <LuUser />
                            </div>
                            <Field autoComplete="off" className="formInput" id="email" name="email" type="email" placeholder="Email" onChange={formik.handleChange} value={formik.values.email} />
                            </div>
                            <ErrorMessage name="email" component="div" className="loginError" /> 
                            <Field id='forgotPwdSubmitBtn' className="pwdSubmitBtn" type="submit" value = "Reset Password" />
                        </div>
                        <Copyright/>
                    </Form>
                )
            }
        </Formik>
    )
}

export default ForgotPwd;