import React from 'react';

const Logout = () => {
 return (
    <div className="row vh-100 g-0">
      <div className="col-lg-6 position-relative d-none d-lg-block">
      <img src="./Oaasyss.jpeg" alt="Oasys Logo" width="750" height="703" />
      </div>
      <div className="col-lg-6">
        <div className="row align-items-center justify-content-center h-100 g-0 px-4 px-sm-0">
          <div className="col col-sm-6 col-lg-7 col-xl-6">
            {/* <a href="#" className="d-flex justify-content-center mb-4">
              <img src="./Logo.svg" alt="Oasys Logo" width="100" />
            </a> */}
            <div className="text-center mb-5">
              <h3 className="fw-bold">Come back soon!</h3>
              <p className="text-secondary">Thank you for using Oasys. You are now successfully logged out.</p>
            </div>
            <form action="">
              <button className="btn btn-success  w-100 mb-4"> Go to sign in page</button>
            </form>
            <p className="mb-1 text-center">
                  &copy; {new Date().getFullYear()} Oasys. All rights reserved.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Logout;
